<template>
  <div
    class="invitation-input-box"
    ref="invitationInputBox"
    @click.once="memberQueryHandleBind"
  >
    <div class="invitation-search-label" v-if="searchtype === 2">
      <span>To:</span>
    </div>
    <div class="invitation-search-con">
      <div class="invitation-activeuser-box">
        <div
          class="invitation-activeuser-item"
          v-for="item in acuser"
          :key="item.userId"
          :data-usid="item.userId"
        >
          <span
            class="invitation-userphoto xpp-user-photo"
            :style="item.headImg | styleUserPhoto"
          ></span>
          <span>{{ item.nickName }}</span>
        </div>
      </div>
      <el-autocomplete
        v-model="memberQuerySearchSelect"
        :fetch-suggestions="memberQuerySearch"
        valueKey="nickName"
        :placeholder="inputplaceholder"
        @select="memberQueryHandleSelect"
      ></el-autocomplete>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "UserSearchInput",
  model: {
    prop: "acuser",
    event: "change"
  },
  props: {
    searchtype: {
      type: Number,
      required: true
    },
    radio: {
      type: String,
      default: "0"
    },
    acuser: {
      type: Object,
      required: true
    }
  },
  computed: {
    inputplaceholder() {
      return this.searchtype === 2 ? "搜索邮箱" : "搜索用户名";
    },
    ...mapGetters(["xppCompanyId"])
  },
  data() {
    return {
      memberQuerySearchSelect: ""
    };
  },
  methods: {
    memberQueryHandleBind() {
      let target = this.$refs.invitationInputBox.getElementsByTagName(
        "input"
      )[0];
      target.addEventListener("keydown", event => {
        switch (event.keyCode) {
          case 8:
          case 46:
            if (this.memberQuerySearchSelect === "") {
              let item = [
                ...document.getElementsByClassName("invitation-activeuser-item")
              ].pop();
              if (item) {
                this.$delete(this.acuser, item.dataset.usid);
                this.$emit("change", this.acuser);
              }
            }
            break;
        }
      });
    },
    async memberQuerySearch(str, cb) {
      let params = {
        companyId: this.xppCompanyId
      };
      if (this.searchtype === 2) {
        params.email = str;
      } else {
        params.nickName = str;
      }
      let { list } = await this.$axios.get("/dis/u/searchuser", { params });
      cb(list.filter(item => !this.acuser[item.userId]));
    },
    memberQueryHandleSelect(item) {
      this.memberQuerySearchSelect = "";
      if (this.acuser[item.userId]) {
        item.checked = false;
        this.$delete(this.acuser, item.userId);
      } else {
        if (this.radio === "1") {
          this.acuser = {};
        }
        item.checked = true;
        this.$set(this.acuser, item.userId, item);
      }
      this.$emit("change", this.acuser);
    }
  }
};
</script>

<style lang="scss" scoped>
.invitation-input-box {
  flex: auto;
  display: flex;
  align-items: stretch;
  box-shadow: 0 0 0 1px #d7d7d7;
  border-radius: 3px;
  min-height: 40px;
}

.invitation-search-label {
  padding-left: 1em;
  padding-right: 1em;
  color: #151516;
  font-size: 14px;
  display: flex;
  align-items: center;
  flex: none;
  span {
    height: 40px;
    line-height: 40px;
  }
}
.invitation-search-con {
  flex: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.invitation-activeuser-box {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: -6px;
  padding-top: 6px;
}

.invitation-activeuser-item {
  margin-right: 4px;
  margin-bottom: 6px;
  border-radius: 4px;
  background: #2d9ee0;
  border: solid 1px #c2e0ff;
  height: 20px;
  line-height: 20px;
  color: #ffffff;
  font-weight: bold;
  line-height: 20px;
  padding: 3px;
  .invitation-userphoto {
    display: inline-block;
    border-radius: 100%;
    background-color: #fff;
    width: 20px;
    height: 20px;
    margin-right: 5px;
    vertical-align: middle;
  }
}
</style>
