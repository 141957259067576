<template>
  <el-dialog
    title="邀请加入"
    width="640px"
    custom-class="xpp-invitation-tojoin"
    :visible.sync="dialogInvitationToJoin.visible"
    :close-on-click-modal="false"
    @close="$DialogInvitationToJoinShow"
    :append-to-body="true"
  >
    <h2 class="invitation-usersearch-title">
      <i class="iconfont icon-folder"></i
      ><span>{{ dialogInvitationToJoin.title }}</span>
    </h2>
    <div class="invitation-usersearch-form">
      <UserSearchInput
        v-model="activeMembersInfo"
        :searchtype="dialogInvitationToJoin.type"
      />
      <el-button type="warning" @click="memberQueryHandleSubmit"
        >邀请</el-button
      >
    </div>
    <div class="invitation-memberlist" v-if="dialogInvitationToJoin.type !== 2">
      <ul>
        <li
          v-for="item in userlistfilter"
          :key="item.userId"
          @click="memberCheck(item)"
        >
          <div
            class="xpp-user-photo"
            :style="item.headImg | styleUserPhoto"
          ></div>
          <div class="xpp-userinfo-describe">
            <b>{{ item.nickName }}</b
            ><br /><span>{{ item.email }}</span>
          </div>
          <div
            class="invitation-userschecked-btn"
            :class="{
              checked: activeMembersInfo[item.userId]
            }"
          >
            <i
              :class="{
                'el-icon-check': activeMembersInfo[item.userId],
                'el-icon-plus': !activeMembersInfo[item.userId]
              }"
            ></i>
          </div>
        </li>
      </ul>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { formParamsFormat } from "@plugins/toolWheel";
import UserSearchInput from "@components/UserSearchInput";

export default {
  name: "InvitationToJoin",
  components: {
    UserSearchInput
  },
  computed: {
    userlistfilter() {
      if (this.member.length) {
        return this.xppTeamMemberList.filter(item => {
          let state = true;
          for (let i = 0; i < this.member.length; i++) {
            if (this.member[i].userId === item.userId) {
              state = false;
            }
          }
          return state;
        });
      } else {
        return this.xppTeamMemberList;
      }
    },
    member() {
      return this.dialogInvitationToJoin.member;
    },
    ...mapGetters([
      "xppCompanyId",
      "xppTeamMemberList",
      "dialogInvitationToJoin"
    ])
  },
  watch: {
    "dialogInvitationToJoin.visible"(val) {
      if (!val) {
        this.activeMembersInfo = {};
        this.memberQuerySearchSelect = "";
      }
    }
  },
  data() {
    return {
      memberQuerySearchSelect: "",
      activeMembersInfo: {}
    };
  },
  methods: {
    memberCheck(item) {
      if (this.activeMembersInfo[item.userId]) {
        this.$delete(this.activeMembersInfo, item.userId);
      } else {
        this.$set(this.activeMembersInfo, item.userId, item);
      }
    },
    async memberQueryHandleSubmit() {
      let { id: resourceId, resourceType, type } = this.dialogInvitationToJoin;
      let params = {
        resourceId,
        companyId: this.xppCompanyId,
        resourceType,
        userIds: Object.keys(this.activeMembersInfo).join()
      };
      if (type === 1) {
        params.roleId = resourceType === "1" ? "5" : "9";
      } else {
        params.roleId = "7";
      }
      await this.$axios.post("/dis/m/addinvitmember", formParamsFormat(params));
      await this.$GetResourceMemberList({ resourceId, resourceType });
      this.$DialogInvitationToJoinShow();
    },
    ...mapMutations(["$DialogInvitationToJoinShow"]),
    ...mapActions(["$GetResourceMemberList"])
  }
};
</script>

<style lang="scss" scoped>
.invitation-usersearch-title {
  margin-bottom: 20px;
  .iconfont {
    color: #f7bc03;
    font-size: 24px;
    margin-right: 0.5em;
  }
  span {
    font-size: 16px;
    font-weight: bold;
    color: #373839;
  }
}

.invitation-usersearch-form {
  display: flex;
  align-items: flex-end;

  .el-button {
    margin-left: 10px;
  }
}

.invitation-memberlist {
  position: relative;
  margin-top: 18px;
  &::after {
    pointer-events: none;
    position: absolute;
    bottom: 0;
    left: 0;
    content: "";
    width: 100%;
    z-index: 2;
    height: 50%;
    box-shadow: inset 0 -40px 30px -20px #fff;
  }
  ul {
    max-height: 400px;
    overflow: auto;
    &::-webkit-scrollbar-track {
      width: 9px;
      background: #fff;
      margin: 5px 0;
    }
    &::-webkit-scrollbar-thumb {
      border: 2px solid #fff;
      background: #eaeaea;
      border-radius: 5px;
    }
    &::-webkit-scrollbar {
      width: 11px;
    }
  }
  li {
    display: flex;
    align-items: center;
    border-radius: 8px;
    padding: 12px;
    height: 36px;
    cursor: pointer;
    &:hover {
      background-color: #f4f1ff;
      box-shadow: inset 0 0 0 1px #d8d0ff;
    }
    &:last-child {
      margin-bottom: 40px;
    }
  }
  .xpp-user-photo {
    width: 30px;
    height: 30px;
    margin-right: 8px;
    background-color: #eee;
  }
  .xpp-userinfo-describe {
    line-height: 16px;
    flex: auto;
  }
  .invitation-userschecked-btn {
    width: 16px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    margin-right: 10px;
    border: 2px solid #f7bc03;
    color: #f7bc03;
    border-radius: 100%;
    i {
      font-weight: bold;
    }
    &.checked {
      background-color: #6ecc51;
      color: #ffff;
      border-color: #6ecc51;
    }
  }
  b {
    font-weight: 600;
    color: #373839;
    font-size: 13px;
  }
  span {
    font-size: 12px;
    color: #8e959a;
  }
}

.invitation-email-label {
  padding-left: 1em;
  color: #373839;
  font-size: 14px;
}
</style>
<style lang="scss">
.xpp-invitation-tojoin {
  .el-dialog__title {
    font-weight: bold;
  }
  .el-dialog__header {
    padding: 30px;
    padding-bottom: 10px;
  }
  .el-dialog__body {
    padding: 30px;
    padding-top: 0;
  }
}
</style>
